.myZoomyStyle:hover {
  -ms-transform: scale(2); /* IE 9 */
  -webkit-transform: scale(2); /* Safari 3-8 */
  transform: scale(2); 
  transition-duration: 2s, 4s;
}
.boxCropper {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.previewCropper {
  overflow: hidden;
}

.previewCropperBack {
  overflow: hidden;
}

.previewCropperPass{
  overflow: hidden;
}
